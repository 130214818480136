import React from 'react';
import './../App.css';
import Heading from './components/heading';
import About from './components/about';
import EventsDesc from './components/events_desc';
import Events from './components/events';
import Team from './components/team';
import Footer from './components/footer';

function MainPage() {
    return (
        <div className="container" id={'mainPage-container'}>
            <Heading/>
            <About/>
            <EventsDesc/>
            <Events/>
            <Team />
            <Footer/>
        </div>
    );
}

export default MainPage;