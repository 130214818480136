import React, {useState} from 'react';
import './../../App.css';
import './../styling/team_style.css';

import AdityaS from './../../Assets/Images/AdityaSankar.jpeg';
import AvanthikaK from './../../Assets/Images/AvanthikaKrishnan.jpeg';
import SaanviV from './../../Assets/Images/SaanviVenugopal.jpeg';
import HODs from './../../Assets/Images/IMG_0359.jpg';
import EventHeads from './../../Assets/Images/IMG_0364.jpg';


const members = [
    {
        name: "Saanvi Venugopal",
        description: "Description for Saanvi Venugopal goes here.",
        image: SaanviV,
    },
    {
        name: "Avanthika Krishnan",
        description: "Description for Avanthika Krishnan goes here.",
        image: AvanthikaK,
    },{
        name: "Aditya Sankar",
        description: "Description for Aditya Sankar goes here.",
        image: AdityaS,
    }
];
const Team = () => {
    const [currentMember, setCurrentMember] = useState(0);

    const handlePrev = () => {
        setCurrentMember(
            currentMember === 0 ? members.length - 1 : currentMember - 1
        );
    };

    const handleNext = () => {
        setCurrentMember(
            currentMember === members.length - 1 ? 0 : currentMember + 1
        );
    };
    let div = <>
        <div className={'container'} id={'team-div'}>

            <h1 className={'primary-heading'} id={'team-div-heading'}>Meet our Team</h1>

            <h2 className={'secondary-heading'} id={'team-type'}>Organising Committee</h2>

            <div className={'carousel'} id={'team-carousel-div'}>
                <button className="nav-button" onClick={handlePrev} id={'team-button'}>
                    &#10094;
                </button>
                <div className="carousel-card" id={'carousel-member-info'}>
                    <img className="member-image" src={members[currentMember].image} alt="Team Member Image"
                         id="team-image"/>
                    <div className="member-text" id={'team-info'}>
                        <h2 className={'member-name'}>{members[currentMember].name}</h2>
                        {/*<p>{members[currentMember].description}</p>*/}
                    </div>

                </div>
                <button className="nav-button" onClick={handleNext}>
                    &#10095;
                </button>
            </div>

            <h2 className={'secondary-heading'} id={'team-type'}>Heads of Departments</h2>

            <div className="card" id={'hod-team-card'}>
                <img className="member-image" src={HODs} alt="HODs"
                     id="hod-team-image"/>
                <div className="member-text" id={'team-info'}>
                    <p className={'team-info-text-line'}>Arav - Finance</p>
                    <p className={'team-info-text-line'}>Ashwanth - Security</p>
                    <p className={'team-info-text-line'}>Aanika - Communications</p>
                </div>

            </div>

            <h2 className={'secondary-heading'} id={'team-type'}>Event Heads</h2>
            <div className="card" id={'eventheads-team-card'}>
                <img className="member-image" src={EventHeads} alt="Event Heads"
                     id="eventheads-team-image"/>
                <div className="member-text" id={'team-info'}>
                    <p className={'team-info-text-line'}>Palak - Test Tube</p>
                    <p className={'team-info-text-line'}>Himaja & Spurti - Excellence Expo</p>
                    <p className={'team-info-text-line'}>Ruchi - Root Issue</p>
                    {/*<p className={'team-info-text-line'}>Anjali - Pencilin</p>*/}
                </div>

            </div>

        </div>
    </>;
    return div
}

export default Team;