import React, {useState, useEffect} from 'react';
import {Link} from 'react-scroll';
import {Navbar, Nav, Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './navbar_style.css'; // Import your CSS file
import Genesis_Logo from './../Assets/Logos/Genesis_Logo_Transperant.png';


function NavigationBar() {
    const [scrolled, setScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const navbarOffset = -155;

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleLinkClick = () => {
        setExpanded(false); // Close the navbar after clicking a link
    };

    return (
        <Navbar
            className={`custom-navbar ${scrolled ? "scrolled" : ""}`}
            expand="md"
            expanded={expanded}
            fixed="top"
        >
            <Container>
                <Navbar.Brand>
                    <img
                        src={Genesis_Logo}
                        alt="genesis_logo"
                        className="navbar_logo"
                        id="genesis_logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setExpanded(!expanded)}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Link
                            to="heading-div"
                            smooth={true}
                            duration={500}
                            offset={navbarOffset}
                            className="navbar-links"
                            onClick={handleLinkClick}
                        >
                            Home
                        </Link>
                        <Link
                            to="about-div"
                            smooth={true}
                            duration={500}
                            offset={navbarOffset}
                            className="navbar-links"
                            onClick={handleLinkClick}
                        >
                            About
                        </Link>
                        <Link
                            to="events-desc-div"
                            smooth={true}
                            duration={500}
                            offset={navbarOffset}
                            className="navbar-links"
                            onClick={handleLinkClick}
                        >
                            Events
                        </Link>
                        <Link
                            to="events-div"
                            smooth={true}
                            duration={500}
                            offset={navbarOffset}
                            className="navbar-links"
                            onClick={handleLinkClick}
                        >
                            Schedule
                        </Link>
                        <Link
                            to="team-div"
                            smooth={true}
                            duration={500}
                            offset={navbarOffset}
                            className="navbar-links"
                            onClick={handleLinkClick}
                        >
                            Team
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;

// function NavigationBar() {
//     const [scrolled, setScrolled] = useState(false);
//     const [expanded, setExpanded] = useState(false);
//
//     // Define the offset variable for consistency
//     const navbarOffset = -155; // Adjust based on your navbar height
//     const newnavbarOffset = -250;
//     useEffect(() => {
//         const handleScroll = () => {
//             if (window.scrollY > 50) {
//                 setScrolled(true);
//             } else {
//                 setScrolled(false);
//             }
//         };
//
//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);
//
//     const handleLinkClick = () => {
//         setExpanded(false); // Close the navbar after clicking a link
//     };
//
//     return (
//         <Navbar
//             className={`custom-navbar ${scrolled ? 'scrolled' : ''}`}
//             expand="md"
//             expanded={expanded}
//         >
//             <Container className={"navbar-container"}>
//                 <Navbar.Brand>
//                     <img src={Genesis_Logo} alt="genesis_logo" className="navbar_logo" id="genesis_logo"/>
//                 </Navbar.Brand>
//                 <Navbar.Toggle
//                     aria-controls="basic-navbar-nav"
//                     onClick={() => setExpanded(!expanded)} // Toggle the navbar open/close
//                 />
//                 <Navbar.Collapse id="basic-navbar-nav">
//                     <Nav className="ms-auto">
//                         <Link
//                             to="heading-div"
//                             smooth={true}
//                             duration={500}
//                             offset={navbarOffset} // Use the variable here
//                             className="navbar-links"
//                             spy={true}
//                             activeClass="active"
//                             onClick={handleLinkClick}
//                         >
//                             Home
//                         </Link>
//                         <Link
//                             to="about-div"
//                             smooth={true}
//                             duration={500}
//                             offset={navbarOffset} // Use the variable here
//                             className="navbar-links"
//                             spy={true}
//                             activeClass="active"
//                             onClick={handleLinkClick}
//                         >
//                             About
//                         </Link>
//                         <Link
//                             to="schedule_heading"
//                             smooth={true}
//                             duration={500}
//                             offset={newnavbarOffset} // Use the variable here
//                             className="navbar-links"
//                             spy={true}
//                             activeClass="active"
//                             onClick={handleLinkClick}
//                         >
//                             Schedule & Events
//                         </Link>
//                         <Link
//                             to="team-div"
//                             smooth={true}
//                             duration={500}
//                             offset={navbarOffset} // Use the variable here
//                             className="navbar-links"
//                             spy={true}
//                             activeClass="active"
//                             onClick={handleLinkClick}
//                         >
//                             Meet our Team
//                         </Link>
//                     </Nav>
//                 </Navbar.Collapse>
//             </Container>
//         </Navbar>
//     );
// }
//
// export default NavigationBar;