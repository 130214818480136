import React from 'react';
import './../../App.css';
import './../styling/about_style.css';

function About() {
    return (
        <div className="container" id={'about-div'}>

            <h1 className="primary-heading" id="about-heading">About Us</h1>

            <div className="card" id={'about-card'}>
                <p className="text" id={'first-text-about'}>Organised and hosted by the students at The
                    International School Bangalore, Genesis is an
                    interschool biology competition. The one-day
                    event invites students from across the city to
                    compete in 4 exciting challenges to showcase
                    their talents and celebrate the Biological
                    Sciences.</p>

                <p className="text">Participation in the event will have no registration
                    fee.</p>

                <p className="text">It will be held on 8th December and will be
                    hosted on the campus of The International
                    School Bangalore.</p>

                <p className="text">As each event has a cap on the number of
                    participant groups, you are advised to register for
                    Genesis as soon as possible through the registration form.</p>

            </div>

        </div>
    );
}

export default About;