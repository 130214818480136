import React, { useEffect, useState } from "react";
import "./../styling/events_style.css";
import { ReactComponent as DNA } from "./../../Assets/Images/DNAHelix.svg"; // Import your SVG

const events_list = [
    {
        id: 1,
        title: "Test Tube",
        category: "Both Categories",
        description: "A quiz competition where contestants, in teams of 3, can pit their biological understanding and trivia against fellow students in their category.",
        time: "9:00 to 12:00"
    },
    {
        id: 2,
        title: "Excellence Expo",
        category: "Both Categories",
        description: "A science fair presenting participants the opportunity to display their scientific understanding through models and research presentations.",
        time: "9:00 to 12:30"
    },
    {
        id: 3,
        title: "Pencilin",
        category: "Junior Category",
        description: "A poster-making challenge where students, in groups of 3, create posters and jingles to present within 2.5 hours.",
        time: "9:00 to 11:30",
        time2: "11:30 to 12:15"
    },
    {
        id: 4,
        title: "Root Issue",
        category: "Senior Category",
        description: "A debate competition where teams of 2-4 members argue their perspective on prepared motions assigned on the event day.",
        time: "8:45 to 12:30"
    },
];

// Predefined coordinates for nodes in the SVG (using percentages for responsiveness)
const nodePositions = [
    { x: 80, y: 10 },  // Event 1 position
    { x: 80, y: 30 },  // Event 2 position
    { x: 80, y: 52.5 },  // Event 3 position
    { x: 80, y: 77.5 },  // Event 4 position
];

const Events = () => {
    const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });

    // Track the dimensions of the SVG for responsiveness
    useEffect(() => {
        const svgElement = document.querySelector(".dna-svg");
        const updateDimensions = () => {
            setSvgDimensions({
                width: svgElement.clientWidth,
                height: svgElement.clientHeight,
            });
        };
        window.addEventListener("resize", updateDimensions);
        updateDimensions(); // Set initial dimensions on load
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <div className="container" id={'events-div'}>
            <h1 className="primary-heading" id="schedule_heading">Events Schedule</h1>
            <div className="dna-wrapper">
                {/* Render the DNA SVG */}
                <DNA className="dna-svg"/>

                {/* Render events dynamically */}
                {events_list.map((event, index) => {
                    const { x, y } = nodePositions[index];
                    const left = (svgDimensions.width * x) / 100; // Convert percentage to pixel value
                    const top = (svgDimensions.height * y) / 100; // Convert percentage to pixel value

                    return (
                        <div
                            key={event.id}
                            className="event-details"
                            style={{
                                left: `${left}px`,
                                top: `${top}px`,
                            }}
                        >
                            <h3 className="event-title">{event.title}</h3>
                            <p className="event-category">{event.category}</p>
                            <p className="event-timing">{event.time}</p>
                            <p className="event-timing">{event.time2}</p>
                            {/* <p className="event-description">{event.description}</p> */}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Events;