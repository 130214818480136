import React from 'react';
import './../../App.css';
import './../styling/heading_style.css';

function Heading() {
    return (
        <div className="container" id={'heading-div'}>

            <h3 className={'subtitle'} id={'genesis-subtitle'}>TISB's Interschool Biology Event</h3>
            {/*<h1 className={'title'} id={'genesis-title'}>Genesis</h1>*/}
            <img src={require('./../../Assets/Logos/Genesis_Text_Img.png')} alt={'Genesis_main_svg'} id={'genesis-text-img'}/>

            <h2 className={'subtitle'} id={'genesis-date'}>December 8th, 2024</h2>
            <button className={'primary-button'} id={'register-button'}><a href={'https://forms.office.com/r/sMfJLGVZXf'} className={'link'} target={'_blank'}>Register Now</a> </button>

        </div>
    );
}

export default Heading;