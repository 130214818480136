import React from "react";
import "../styling/events_desc_style.css"; // CSS for styling the cards


const events = [
    { name: "Test Tube", description: "A quiz competition where contestants, in teams of 3, can pit\n" +
            "their biological understanding and trivia against fellow\n" +
            "students in their category, vying to acquire the most points\n" +
            "possible in 3 thrilling rounds." },
    { name: "Excellence Expo", description: "A science fair, this event presents participants (1-5 people\n" +
            "per entry) the opportunity to display their scientific\n" +
            "understanding of a provided theme: through models for the\n" +
            "junior category and through research presentations for the\n" +
            "senior category." },
    { name: "Pencilin", description: "Available solely for the Junior Category, this contest is a\n" +
            "poster-making challenge where students, in groups of 3,\n" +
            "will be provided with a topic and supplies on the spot.\n" +
            "They must make the most creative poster possible as well as\n" +
            "a jingle with which to present it within 2.5 hours." },
    { name: "Root Issue", description: "Available solely for the Senior Category, contestants in this\n" +
            "debate competition must successfully argue their perspective in\n" +
            "teams of 2-4 members. Over 3 rounds, they must present their\n" +
            "position convincingly to the judges based on a variety of prepared\n" +
            "motions-although they will only be assigned ‘For’ or ‘Against’ on\n" +
            "the day." }
];

// EventSection Component
function Events_Desc() {
    return (
        <section className={'container'} id="events-desc-div">
            <h2 className="primary-heading" id={'events_desc_title'}>Our Events</h2>
            <div className="event-cards-container" id={'events-card-div'}>
                {events.map((event, index) => (
                    <div key={index} className="card" id={'event-card'}>
                        <h3 className="event-name">{event.name}</h3>
                        <p className="event-description">{event.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Events_Desc;
